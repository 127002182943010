<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w400 mb10 mr10" placeholder="输入企业名称关键字搜索" @change="searchFun"
                    v-model="searchData.name" />
                <!-- <el-button type="primary" @click="searchFun">搜索</el-button> -->
            </div>
            <div style="margin-left: auto">
                <el-button class="ma mr10" type="primary" @click="showAddModel">新增企业</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="companyName" align="center" label="企业名称" show-overflow-tooltip />
                <el-table-column prop="legalName" align="center" label="法人代表" show-overflow-tooltip />
                <el-table-column prop="startTime" align="center" label="成立时间" show-overflow-tooltip />
                <el-table-column prop="registerAddress" align="center" label="注册地址" show-overflow-tooltip />
                <el-table-column prop="businessAddress" align="center" label="办公地址" show-overflow-tooltip />
                <el-table-column prop="introduce" align="center" label="企业介绍" show-overflow-tooltip />
                <el-table-column align="center" label="账号列表" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row, 'show')">查看列表</el-button>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row, 'set')">编辑</el-button>
                        <!-- <el-button class="mr10" style="color:#F56C6C" type="text" @click="delCompany(scope.row)">删除
                        </el-button> -->
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="900px" center @close="closeModel">
            <div v-if="showType == 'set'">
                <el-form :model="formData" :rules="rules" ref="formData" label-width="130px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label-position="top" label="" prop="logo">
                                <div>LOGO</div>
                                <el-upload accept="image/jpeg,image/png" :action="uploadUrl" :class="{
                        disabled: logoUploadDisabled,
                    }" list-type="picture-card" :before-upload="beforeAvatarUpload" :on-remove="handleRemoveLogo"
                                    :on-success="uploadLogoImg" :file-list="logoFile" :limit="1">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label-position="top" label="" prop="seal">
                                <div>公司章</div>
                                <el-upload accept="image/jpeg,image/png" :action="uploadUrl" :class="{
                        disabled: sealUploadDisabled,
                    }" list-type="picture-card" :before-upload="beforeAvatarUpload" :on-remove="handleRemoveSeal"
                                    :on-success="uploadSealImg" :file-list="sealFile" :limit="1">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="企业名称" prop="companyName">
                                <el-input v-model="formData.companyName" placeholder="请输入企业名称" class="w100p"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="信用代码" prop="creditCode">
                                <el-input v-model="formData.creditCode" placeholder="请输入信用代码" class="w100p"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="法人代表" prop="legalName">
                                <el-input placeholder="请输入法人代表" v-model="formData.legalName" type="text"
                                    class="w100p" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="企业简称(英文)" prop="shortName">
                                <el-input v-model="formData.shortName" placeholder="请输入企业简称" class="w100p"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="成立时间" prop="startTime">
                                <el-date-picker v-model="formData.startTime" value-format="yyyy-MM-dd" align="right"
                                    class="w100p" type="date" placeholder="选择日期" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="企业类型" prop="companyType" clearable>
                                <el-select class="w100p" placeholder="请选择企业类型" v-model="formData.companyType">
                                    <el-option :label="item.dictLabel" :value="item.dictValue"
                                        v-for="(item, index) in companyTypeData" :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="注册地址" prop="registerAddress">
                        <el-input placeholder="请输入注册地址" v-model="formData.registerAddress" type="text" />
                    </el-form-item>
                    <el-form-item label="办公地址" prop="businessAddress">
                        <el-input placeholder="请输入办公地址" v-model="formData.businessAddress" type="text" />
                    </el-form-item>
                    <el-form-item label="企业介绍" prop="introduce">
                        <el-input placeholder="企业介绍" v-model="formData.introduce" maxlength="200" type="textarea"
                            rows="5" show-word-limit />
                    </el-form-item>
                    <el-divider content-position="left">设置制度数据</el-divider>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="打印人" prop="printName">
                                <el-input placeholder="请输入打印人" v-model="formData.printName" type="text" class="w100p" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="制度日期" prop="fileDay">
                                <el-date-picker v-model="formData.fileDay" value-format="yyyy-MM-dd" align="right"
                                    class="w100p" type="date" placeholder="选择日期" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="企业简称(字号)" prop="companyWord">
                                <el-input v-model="formData.companyWord" placeholder="请输入企业简称" class="w100p"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">账号管理</el-divider>
                    <div class="pr30">
                        <table border="1" cellspacing="0" class="tableBorder">
                            <thead>
                                <tr class="h40 baf7fafa">
                                    <td class="textc itemTitle mw60"></td>
                                    <td class="textc itemTitle">平台名称</td>
                                    <td class="textc itemTitle mw100">平台网址</td>
                                    <td class="textc itemTitle mw100">业 务</td>
                                    <td class="textc itemTitle mw100">账 号</td>
                                    <td class="textc itemTitle mw100">密 码</td>
                                </tr>
                            </thead>
                            <tbody v-if="formData.message.length > 0">
                                <tr v-for="(row, index) in formData.message" :key="index">
                                    <td class="textc itemContent">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="textc itemContent">
                                        <!-- <el-input class="textc" v-model="row.name" placeholder="平台名称"></el-input> -->
                                        <el-autocomplete class="inline-input" v-model="row.name"
                                            :fetch-suggestions="querySearch" placeholder="平台名称"
                                            @select="selectPlatform($event,index)">
                                            <template slot-scope="{ item }">
                                                <div class="flex-a-b-c">
                                                    <div class="name text-overflow" :title="item.name">{{ item.name }}</div>
                                                </div>
                                            </template>
                                        </el-autocomplete>
                                    </td>
                                    <td class="textc itemContent">
                                        <el-input class="textc" v-model="row.url" placeholder="平台网址"></el-input>
                                    </td>
                                    <td class="textc itemContent">
                                        <el-input class="textc" v-model="row.userName" placeholder="名称"></el-input>
                                    </td>
                                    <td class="textc itemContent">
                                        <el-input class="textc" v-model="row.account" placeholder="账号"></el-input>
                                    </td>
                                    <td class="textc itemContent">
                                        <el-input class="textc" v-model="row.password" placeholder="密码"></el-input>
                                        <div class="tool d-flex">
                                            <div>
                                                <i class="eicon el-icon-plus" @click="addUser()" title="添加账号"></i>
                                            </div>
                                            <div>
                                                <i class="eicon el-icon-delete" @click="delUser(index)"
                                                    title="删除账号"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </el-form>
            </div>
            <div v-else>
                <el-divider content-position="left">{{ formData.companyName }} - 账号管理</el-divider>
                <div>
                    <table border="1" cellspacing="0" class="tableBorder">
                        <thead>
                            <tr class="h40 baf7fafa">
                                <td class="textc itemTitle w60"></td>
                                <td class="textc itemTitle">平台名称</td>
                                <td class="textc itemTitle">业 务</td>
                                <td class="textc itemTitle">账 号</td>
                                <td class="textc itemTitle">密 码</td>
                            </tr>
                        </thead>
                        <tbody v-if="formData.message.length > 0">
                            <tr class="h40" v-for="(row, index) in formData.message" :key="index">
                                <td class="textc itemContent">
                                    {{ index + 1 }}
                                </td>
                                <td class="textc itemContent">
                                    <a :href="row.url" class="cblue" target="_blank" title="单击跳转">{{ row.name }}</a>
                                </td>
                                <td class="textc itemContent curPointer" title="单击复制" @click="copyFun(row.userName)">
                                    {{ row.userName }}
                                </td>
                                <td class="textc itemContent curPointer" title="单击复制" @click="copyFun(row.account)">
                                    {{ row.account }}
                                </td>
                                <td class="textc itemContent curPointer" title="单击复制" @click="copyFun(row.password)">
                                    {{ row.password }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr class="h40">
                                <td class="textc" colspan="5">
                                    暂无账号列表
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <span v-if="showType == 'set'" slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { setProjectCompany, getProjectCompanyList, getByDictType } from "@/api/projectAdmin";
import { copyText } from "@/utils";
export default {
    name: "projectCompany",
    components: {
        commonTable,
    },
    data() {
        return {
            pickerOptions: this.$store.state.pickerOptions,
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            loadingBtn: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            companyTypeData: [],
            formData: {
                companyName: "",
                creditCode: "",
                businessAddress: "",
                introduce: "",
                legalName: "",
                companyType: "",
                registerAddress: "",
                startTime: "",
                shortName: "",
                logo: "",
                seal: "",
                companyWord: "",// 企业简称（字号）
                fileDay: "",// 制度日期
                printName: "",// 打印人/记录人/提案人
                message: [
                    {
                        name: "", userName: "", account: "", password: "", url: ""
                    }
                ]
            },
            rules: {
                companyName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入企业名称",
                },
                legalName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入法人代表",
                },
                companyType: {
                    required: true,
                    trigger: "change",
                    message: "请选择企业类型",
                },
            },
            formType: "",
            showType: "",
            updateId: 0,
            //图片上传配置
            ossUrl: this.$store.state.ossUrl,
            uploadUrl: this.$store.state.uploadingUrl,
            logoFile: [],
            sealFile: [],
        };
    },
    computed: {
        logoUploadDisabled() {
            return this.formData.logo !== "";
        },
        sealUploadDisabled() {
            return this.formData.seal !== "";
        }
    },
    created() {
        this.getList();
        this.getByDictType();
    },
    methods: {
        copyFun(text) {
            copyText(text)
            this.$message.success('复制成功！');
        },
        querySearch(str, cb) {
            let initData = [
                { name: "广东省阳光平台", userName: "高企", url: "https://pro.gdstc.gd.gov.cn" },
                { name: "科技部政务服务平台", userName: "火炬、科小、高企编号", url: "https://fuwu.most.gov.cn/" },
                { name: "惠企利民", userName: "加计扣除、工程技术中心、产学研", url: "https://113.106.103.75/#/home" },
                { name: "珠海市政务服务", userName: "综合性网站", url: "https://www.gdzwfw.gov.cn/?region=440400" },
                { name: "优质中小企业梯度培育平台", userName: "专精特新小巨人", url: "https://zjtx.miit.gov.cn/zxqySy/main" },
                { name: "广东省职称管理系统", userName: "职称申报", url: "https://ggfw.hrss.gd.gov.cn/gdweb/ggfw/web/pub/ggfwzyjs.do" },
                { name: "珠海市人力资源和社会保障网上服务平台", userName: "职称申报", url: "https://wsfw.zhrsj.zhuhai.gov.cn/zhrsClient/index.do?doMethod=pageHome" },
                { name: "广东省知识产权业务管理阳光政务平台", userName: "珠海工匠", url: "https://amr.gd.gov.cn/zscq/login" },
            ]
            cb(initData);
        },
        selectPlatform(data,i) {
            this.formData.message[i].name=data.name;
            this.formData.message[i].url=data.url;
            this.formData.message[i].userName=data.userName;
        },
        /**@method 添加设备 */
        addUser() {
            this.formData.message.push({
                name: "", userName: "", account: "", password: "", url: ""
            });
        },
        delUser(i) {
            if (this.formData.message.length > 1) {
                this.formData.message.splice(i, 1)
            }
        },
        /**@method 获取字典数据 */
        getByDictType() {
            getByDictType({ dictType: "project_company_type", status: 0 }).then(res => {
                if (res.code === 200) {
                    this.companyTypeData = res.data.sysDictDataList;
                }
            })
        },
        //验证图片类型/大小
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg" || file.type === "image/png";
            const isLt1M = file.size / 1024 / 1024 < 10;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG或PNG 格式!");
            }
            if (!isLt1M) {
                this.$message.error("上传头像图片大小不能超过 10MB!", false);
            }
            return isJPG && isLt1M;
        },
        uploadLogoImg(res, file, fileList) {
            this.formData.logo = this.ossUrl + '/' + res.data;
        },
        uploadSealImg(res, file, fileList) {
            this.formData.seal = this.ossUrl + '/' + res.data;
        },
        handleRemoveLogo(file, fileList) {
            this.formData.logo = "";
        },
        handleRemoveSeal(file, fileList) {
            this.formData.seal = "";
        },
        closeModel() {
            this.logoFile = [];
            this.sealFile = [];
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 提交 */
        addUpdate() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData
                    };
                    let message = "新增成功";
                    if (this.updateId) {
                        params.pcId = this.updateId;
                        message = "修改成功"
                    }
                    this.loadingBtn = true;
                    setProjectCompany(params).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            if (this.updateId) {
                                sessionStorage.removeItem("checkedProjectCompany");
                            }
                            this.$message.success(message)
                            this.searchData.name = "";
                            this.searchFun();
                            this.showModel = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                companyName: this.searchData.name
            };
            try {
                this.loading = true;
                let res = await getProjectCompanyList(params)
                this.loading = false;
                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增企业信息";
            this.formType = "add";
            this.showType = "set";
            this.formData = {
                companyName: "",
                creditCode: "",
                businessAddress: "",
                introduce: "",
                legalName: "",
                companyType: "",
                registerAddress: "",
                startTime: "",
                shortName: "",
                logo: "",
                seal: "",
                companyWord: "",// 企业简称（字号）
                fileDay: "",// 制度日期
                printName: "",// 打印人/记录人/提案人
                message: [{
                    name: "", userName: "", account: "", password: "", url: ""
                }]
            };
            this.logoFile = [];
            this.sealFile = [];
            this.updateId = 0;
        },
        /**@method 删除公司 */
        delCompany(row) {
            let params = {
                pcId: row.pcId,
                isDel: 1
            };
            setProjectCompany(params).then(res => {
                if (res.code === 200) {
                    this.$message.success("删除成功");
                    this.searchFun();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(data, type) {
            this.showModel = true;
            this.formType = "update";
            this.updateId = data.pcId;
            this.showType = type;
            this.formData = {
                companyName: data.companyName,
                creditCode: data.creditCode,
                businessAddress: data.businessAddress,
                introduce: data.introduce,
                legalName: data.legalName,
                companyType: data.companyType,
                registerAddress: data.registerAddress,
                startTime: data.startTime,
                shortName: data.shortName,
                logo: data.logo ? data.logo : "",
                seal: data.seal ? data.seal : "",
                companyWord: data.companyWord,// 企业简称（字号）
                fileDay: data.fileDay,// 制度日期
                printName: data.printName,// 打印人/记录人/提案人
                message: data.message ? JSON.parse(data.message) : [{
                    name: "", userName: "", account: "", password: "", url: ""
                }]
            };
            if (type == 'set') {
                this.modelTitle = "编辑企业信息";
            } else if (type == 'show') {
                this.modelTitle = "账号列表";
                this.formData.message = data.message ? JSON.parse(data.message) : []
            }
            if (data.logo) {
                this.logoFile = [{ url: data.logo }];
            }
            if (data.seal) {
                this.sealFile = [{ url: data.seal }];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.itemContent {
    position: relative;
}

.tool {
    display: block;
    position: absolute;
    top: 50%;
    right: -41px;
    width: 40px;
    height: 30px;
    margin-top: -15px;
    background: #f1f2f4;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    align-items: center;

    .eicon {
        font-size: 18px;
        color: #666;
        cursor: pointer;
        height: 20px;
        width: 20px;
        text-align: center;
        line-height: 25px;
    }

    .el-icon-delete {
        color: #F56C6C;
    }
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>